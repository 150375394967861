import React, {Component} from 'react';
import {action} from 'mobx';
import {observer} from 'mobx-react';
import * as cn from 'classnames';

@observer
class FormInput extends Component {
    _onChange = this.props.onChange || (() => {});

    get value() {return this.props.form.fd[this.props.name];}
    set value(v) {this.props.form.fd[this.props.name] = v;}
    get error() {return this.props.form.err[this.props.name];}
    set error(v) {this.props.form.err[this.props.name] = v;}

    get boxClasses() {
        return cn(
            'input-box',
            {
                'not-valid': this.error,
                'valid': (!this.error && this.value)
            }
        );
    }

    componentDidMount() {
        this.props.form.inputs[this.props.name] = this;
    }

    render() {
        return (
            <div className={this.boxClasses}>
                {this.props.label && <label>{this.props.label}</label>}
                <div className="area">
                    <input
                        type={this.props.type || 'text'}
                        name={this.props.name}
                        value={this.value}
                        readOnly={this.props.readOnly}
                        disabled={this.props.disabled}
                        pattern={this.props.pattern || 'text'}
                        placeholder={this.props.placeholder}
                        maxLength={this.props.maxLength || 256}
                        onChange={this.onChange}
                        onBlur={() => this.validate()}
                    />
                </div>
                {this.error && <span className="error">{this.error}</span>}
            </div>
        );
    }

    @action
    validate() {
        const {required = false, label} = this.props;

        if(required && !this.value) return this.error = `Please enter ${label}`;

        this.error = null;
    }

    @action
    onChange = e => {
        const value = e.target.value;
        this.value = value;
        this.validate();
        this.props.form.isFormChanged = true;
        this._onChange(value);
    };
}

export default FormInput;
