import React, {Component} from 'react';
import {observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import Form from '../components/Form';
import FormInput from '../components/FormInput';

@inject('store')
@observer
class AddWord extends Component {
    @observable
    fd = {
        ru: '',
    };

    get items() {
        if(!this.fd.ru) return this.props.store.data;
        return this.props.store.data.filter(x => x.ru.includes(this.fd.ru));
    }

    componentDidMount() {
        window.AddWord = this
    }

    render() {
        return (
            <div className="add-word-page">
                <h1>..Add new word..</h1>
                <Form fd={this.fd} onSubmit={this.onSubmit} render={form => (<>
                    <FormInput form={form} label="RU" name="ru" required />
                    {/*<FormInput form={form} label="DE" name="de" required />*/}
                    <button><span>SAVE</span></button>
                </>)}/>

                <div className="words-box">
                    {this.items.map(x => (<div key={x.ru} className="word">{x.ru}</div>))}
                </div>
            </div>
        );
    }

    onSubmit = fd => {
        console.log(fd);
        console.log(this.props.store.data);
        this.props.store.data.push({...fd});
        this.props.store.saveData()
            .then(() => {
                for(let k in this.fd) this.fd[k] = '';
            });
    };
}

export default AddWord;
