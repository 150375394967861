import React, {Component} from 'react';
import {observable, action} from 'mobx';
import {observer} from 'mobx-react';

@observer
class Form extends Component {
    @observable loading = true;
    @observable isFormChanged = false;
    @observable fd = this.props.fd;
    @observable err = this.makeErrObject();
    inputs = {};

    get hasFormErrors() {
        for(let k in this.err) if(this.err[k]) return true;
        return false;
    }

    componentDidMount() {
        window.Form = this
    }

    render() {
        return (
            <form onSubmit={this.onSubmit} autoComplete="off" noValidate>
                {this.props.render(this)}
            </form>
        );
    }

    makeErrObject() {
        const o = {...this.fd};
        for(let k in o) o[k] = null;
        return o;
    }

    validateForm() {
        for(let k in this.inputs) this.inputs[k].validate();
    }

    @action
    onSubmit = e => {
        e.preventDefault();
        this.validateForm();

        setTimeout(() => {
            if(this.hasFormErrors) return;
            console.info('submit fd', {...this.fd});
            this.props.onSubmit(this.fd);
        }, 200);
    };
}

export default Form;
