import React, {Component} from 'react';
import {observable} from 'mobx';
import {observer} from 'mobx-react';

@observer
class Study extends Component {
    @observable
    fd = {
        ru: '',
        de: '',
    };
    @observable err = this.makeErrObject();

    render() {
        return (
            <div className="study-page">
                Study page
            </div>
        );
    }
}

export default Study;
