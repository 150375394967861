import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import {Provider} from 'mobx-react';
import {createBrowserHistory} from 'history';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';

import 'react-notifications/lib/notifications.css';
import './scss/index.scss';


const history = createBrowserHistory();

ReactDOM.render((
    <Provider store={store}>
        <Router history={history}>
            <App/>
        </Router>
    </Provider>

), document.getElementById('root'));

serviceWorker.unregister();
