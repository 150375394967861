import React, {Component}  from 'react';
import {observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
import AddWord from './pages/AddWord';
import Study from './pages/Study';

@inject('store')
@withRouter
@observer
class App extends Component {
    @observable text = '';

    componentDidMount() {
        window.App = this

        this.props.store.getData();
    }

    render() {
        return (
            <div className="app">
                {/*<div className="menu">*/}
                {/*    <Link to="/">Add word</Link>*/}
                {/*    <Link to="/study">Study</Link>*/}
                {/*</div>*/}
                <Switch>
                    <Route path="/" component={AddWord}/>
                    <Route path="/study" component={Study}/>
                </Switch>
                <NotificationContainer/>
            </div>
        );
    }
}

export default App;
