import {observable, action} from 'mobx';
import {Msg} from './api';

class Store {
    @observable data = [];
    @observable loading = false;
    // API_URL = 'http://localhost:8181';
    API_URL = 'http://sprache.ezi.co.ua/api/';

    @action setData = data => {
        if(data) this.data = data;
        console.log('store loaded', [...this.data]);
    };

    getData() {
        fetch(this.API_URL)
            .then(r => r.json())
            .then(this.setData)
            .catch(err => Msg.error(err.message));
    }

    saveData() {
        return fetch(this.API_URL, {method: 'POST', body: JSON.stringify(this.data)})
            .then(r => r.json())
            .then(() => Msg.success('Database updated'))
            .catch(err => Msg.error(err.message));
    }
}

export default new Store();
