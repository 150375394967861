import {NotificationManager} from 'react-notifications';

export const Msg = {
    success(text) {
        NotificationManager.success(text, 'Success', 3000);
    },
    warning(text) {
        NotificationManager.warning(text, 'Warning', 3000);
    },
    error(text) {
        NotificationManager.error(text, 'Error', 5000);
    },
    info(text) {
        NotificationManager.info(text);
    },
};

export function saveStore() {

}
